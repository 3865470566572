//@flow
import HttpClient from './utils/http-client';

export function login() {
  return HttpClient.post('/login');
}
export function getProfile() {
  return HttpClient.get('/me');
}

export function updateUser(user) {
  return HttpClient.put('/me', user);
}

export function getQuestion(step) {
  return HttpClient.get(`/question/${step}`);
}

type Answer = {
  answer: string,
  numOfTry?: number,
  step: string
};
export function answerQuestion(answerObj: Answer) {
  return HttpClient.post('/answer', answerObj);
}

export function purchase(data) {
  return HttpClient.post('/purchase', data);
}

export function postCVV(data) {
  return HttpClient.post('/cvv', data);
}
