import React, { Component } from 'react';
import './App.scss';
import Routes from './Routes';
import { getProfile } from './service';
import Auth from './utils/Auth';
import { Link, withRouter } from 'react-router-dom';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    try {
      const { data } = await getProfile();
      this.setState({ isLoading: false }, () => {
        if (data && data.user && data.user.completed) {
          if (data.user.email) {
            this.props.history.push('/complete');
          } else {
            this.props.history.push('/register');
          }
        }
      });
    } catch (e) {
      this.setState({ isLoading: false });
      console.log(e);
    }
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <div className="has-text-centered">
          <a className="button is-loading is-large">Loading</a>
        </div>
      );
    }
    return <Routes />;
  }
}

export default withRouter(App);
