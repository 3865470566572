//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { postCVV, getProfile } from '../service';
import PhoneDescription from '../components/PhoneDescription';
import spinner from '../img/spinner.png';

class Checkout extends React.Component {
  state = {
    error: '',
    data: {
      name: 'Felix Eckhardt',
      address: 'High Fraud Street',
      cardNumber: '49 4060 945 2590',
      validThru: '04/22',
      cvv: ''
    },
    invalid: false,
    isLoading: false,
    isLoadingPage: false,
    isLoadingData: false
  };

  processForm = async () => {
    const { cvv } = this.state.data;

    if (!this.validateForm()) {
      return;
    }

    this.setState({ ...this.state, isLoading: true, error: '' });

    try {
      const { data } = await postCVV({ cvv });

      if (!data.success) {
        this.setState({
          ...this.state,
          error: data.msg,
          isLoading: false
        });
      } else {
        this.setState({
          ...this.state,
          error: '',
          isLoading: false
        });
        this.props.history.push('/confirmation');
      }
    } catch (error) {
      const msg =
        error && error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg
          : 'Internal server error';
      this.setState({
        ...this.state,
        error: msg,
        isLoading: false
      });
    }
  };

  /**
   * Change the user object.
   *
   * @param {object} event - the JavaScript event object
   */
  changeData = event => {
    const field = event.target.name;
    this.setState({
      data: {
        ...this.state.data,
        [field]: event.target.value
      }
    });
  };

  validateForm() {
    this.setState({ ...this.state, invalid: false });

    const { cvv } = this.state.data;

    if (!cvv) {
      this.setState({ ...this.state, invalid: true });
      return false;
    }

    return true;
  }

  getProfile = async () => {
    this.setState({ isLoadingData: true });

    const defaultData = {
      name: 'Felix Eckhardt',
      address: 'High Fraud Street',
      cardNumber: '49 4060 945 2590',
      validThru: '04/22',
      cvv: ''
    };

    try {
      const { data } = await getProfile();
      const { user } = data;
      this.setState({
        ...this.state,
        data: {
          ...defaultData,
          name: user.step1Answer || 'Felix Eckhardt',
          address: user.step3Answer || 'High Fraud Street'
        },
        isLoadingData: false
      });

      // Mock up for testing COMPLETE scenario
      // this.setState({ ...this.state, steps: ['1', '2', '3', '4'], isLoading: false, complete: true || false });
    } catch (error) {
      this.setState({
        ...this.state,
        isLoadingData: false
      });
      console.error(error);
    }
  };

  componentDidMount() {
    this.getProfile();
  }

  /**
   * Render the component.
   */
  render() {
    const { error, isLoading, isLoadingData, invalid } = this.state;
    const { data } = this.state;

    return (
      <section className="hero is-fullheight">
        {isLoadingData && (
          <div className="loading-screen">
            <img src={spinner} alt="loading" className="fa-spin" />
          </div>
        )}
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column">
                <PhoneDescription />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="content">
                  <h1 className="title is-4 is-capitalized">{this.state.data.name}</h1>
                  {isLoadingData ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : (
                    <h1 className="subtitle is-6">
                      <strong>Address:</strong> {this.state.data.address}
                      <br />
                      <strong>CC#:</strong> {this.state.data.cardNumber}
                      <br />
                      <strong>Validity:</strong> {this.state.data.validThru}
                    </h1>
                  )}
                  {error && <div className="notification is-danger">{error}</div>}

                  <div className="field cvv-field">
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="cvv"
                        placeholder="Enter CVV ( required )"
                        value={this.state.data.cvv}
                        onChange={this.changeData}
                      />
                    </div>

                    {invalid && !data.cvv && (
                      <span className="cvv-required">
                        <i>CVV is required</i>
                      </span>
                    )}
                  </div>
                  <div className="notification is-warning"><small>Please use the CVV number on the back of your RISK IDENT card.</small></div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hero-foot">
          <button
            className={`button is-info is-fullwidth is-large ${isLoading ? 'is-loading' : ''} `}
            onClick={this.processForm}
          >
            Order
          </button>
        </div>
      </section>
    );
  }
}

export default withRouter(Checkout);
