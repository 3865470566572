//@flow
import React from 'react';
import logo from '../img/logo.png';
import spinner from '../img/spinner.png';
import prize from '../img/nitendo.jpg';

class Complete extends React.Component {
  state = {
    isLoading: false
  };
  /**
   * Render the component.
   */

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoading: !this.state.isLoading
      });
    }, 2000);
  }
  render() {
    const { isLoading } = this.state;

    return (
      <section className="hero has-no-footer is-info">
        {!isLoading && (
          <div className="loading-screen">
            <img src={spinner} alt="loading" className="fa-spin" />
          </div>
        )}

        <div className="hero-body">
          <div className="container">
            <h1 className="title  is-4">
              You successfully signed up for the RISK IDENT raffle!
            </h1>

            <div className="content">
             <div className="box">
             <figure className="image is-fullwidth">
                <img src={prize} alt="iPhone" />
              </figure>
              <p>
                You could now win a Nintendo Switch! The winner will be drawn at random from all participants
                who successfully completed the game.
              </p>
             </div>

            </div>
            <div className="notification is-warning is-size-6 has-text-weight-semibold">
              <div className="content">
                <p>
                  <strong>Conditions to win:</strong>
                </p>
                <p>
                  1. Be at the <strong>Empire Riverside Hotel Ballsaal at 17:15, on September 19th, 2019</strong>.
                </p>
                <p>2. Bring your RISK IDENT Credit Card!</p>
                <p>3. You must be physically present to be eligible to win.</p>
              </div>
            </div>
            <p className="is-italic is-size-7">
              The raffle duration will be between 5 and 20 min, depending on participation.
            </p>
          </div>
          <div className="content is-italic">
            <p />
            <p> Good Luck!</p>
            <p>
              Sincerely,
              <br />
              the RISK IDENT TEAM
            </p>
            <p>
              Visit us at <a href="http://riskident.com">riskident.com</a> and join the fight
              against fraud.
            </p>
            <p className="has-text-centered">
              <a href="http://riskident.com">
                <img src={logo} alt="risk ident logo" />
              </a>
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default Complete;
