//@flow
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logo.png';
import spinner from '../img/spinner.png';

class Confirmation extends React.Component {
  state = {
    isLoading: false
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoading: !this.state.isLoading
      });
    }, 2000);
  }

  /**
   * Render the component.
   */
  render() {
    const { isLoading } = this.state;
    return (
      <section className="hero is-info is-fullheight ">
        {!isLoading && (
          <div className="loading-screen">
            <img src={spinner} alt="loading" className="fa-spin" />
          </div>
        )}
        <div className="hero-body">
          <div className="container">
            <h1 className="title has-text-white">Your order could not be confirmed.</h1>
            <div className="content">
              <p>The RISK IDENT software has detected the following anomalies:</p>
              <ol type="1" className="has-text-weight-bold">
                <li>
                  Identity Theft <i className="fas fa-angry" />
                </li>
                <li>
                  Stolen Credit Card <i className="fas fa-angry" />
                </li>
                <li>
                  Manipulated Network <i className="fas fa-angry" />
                </li>
              </ol>

              <p> Therefore your purchase could not be completed.</p>
              <Link className="button is-rounded is-white is-medium" to="/messenger/5">
                Ask fraudster for help
              </Link>
            </div>
          </div>
        </div>
        <div className="home-logo">
          <img src={logo} alt="riskident logo" />
        </div>
      </section>
    );
  }
}

export default Confirmation;
