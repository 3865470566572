//@flow
import React from 'react';
import { Link } from 'react-router-dom';
import { getProfile } from '../service';

class Dashboard extends React.Component {
  state = {
    steps: [],
    isLoading: true,
    incompleteOrder: false,
    error: ''
  };

  constructor() {
    super();
    this.tryOrder = this.tryOrder.bind(this);
  }

  getProfile = async () => {
    this.setState({ isLoading: true });

    try {
      const { data } = await getProfile();
      const { user } = data;
      this.setState({ ...this.state, steps: user.steps, isLoading: false });

      // Mock up for testing COMPLETE scenario
      // this.setState({ ...this.state, steps: ['1', '2', '3', '4'], isLoading: false, complete: true || false });
    } catch (error) {
      const msg =
        error && error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg
          : 'Internal server error';
      this.setState({
        error: msg,
        isLoading: false
      });
      console.error(msg);
    }
  };

  checkStep(step) {
    const { steps } = this.state;
    return steps.indexOf(step) >= 0;
  }

  tryOrder() {
    const { steps } = this.state;

    if (steps.length >= 4) {
      this.props.history.push('/checkout');
    } else {
      this.setState({ ...this.state, incompleteOrder: true });
    }
  }

  componentDidMount() {
    this.getProfile();
  }

  /**
   * Render the component.
   */
  render() {
    const { isLoading, steps, incompleteOrder, error } = this.state;
    return (
      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">Todo List</h1>
            <h2 className="subtitle">
              When you are ready with all four steps, we can order the merchendise. Without paying of
              course ;)
            </h2>
            {!isLoading && !error ? (
              <div className="content">
                {this.checkStep('1') ? (
                  <div className="step-block is-complete">
                    <div className="step-block__step">1.</div>
                    <div className="step-block__title">Steal an Identity</div>
                    <div className="step-block__icon">
                      <span className="icon has-text-white is-medium">
                        <i className="fas fa-check fa-lg" />
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="step-block">
                    <div className="step-block__step">1.</div>
                    <div className="step-block__title">
                      {' '}
                      <a href={'/messenger/1'}>Steal an Identity</a>
                    </div>
                    <div className="step-block__icon">
                      <span className="icon has-text-info is-medium">
                        <i className="fas fa-chevron-right fa-lg" />
                      </span>
                    </div>
                  </div>
                )}

                {this.checkStep('2') ? (
                  <div className="step-block is-complete">
                    <div className="step-block__step">2.</div>
                    <div className="step-block__title">Get a valid Credit Card</div>
                    <div className="step-block__icon">
                      <span className="icon has-text-white is-medium">
                        <i className="fas fa-check fa-lg" />
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="step-block">
                    <div className="step-block__step">2.</div>
                    <div className="step-block__title">
                      {' '}
                      <a href={'/messenger/2'}>Get a valid Credit Card</a>
                    </div>
                    <div className="step-block__icon">
                      <span className="icon has-text-info is-medium">
                        <i className="fas fa-chevron-right fa-lg" />
                      </span>
                    </div>
                  </div>
                )}

                {this.checkStep('3') ? (
                  <div className="step-block is-complete">
                    <div className="step-block__step">3.</div>
                    <div className="step-block__title">Find a Delivery Address</div>
                    <div className="step-block__icon">
                      <span className="icon has-text-white is-medium">
                        <i className="fas fa-check fa-lg" />
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="step-block">
                    <div className="step-block__step">3.</div>
                    <div className="step-block__title">
                      {' '}
                      <a href={'/messenger/3'}>Find a Delivery Address</a>
                    </div>
                    <div className="step-block__icon">
                      <span className="icon has-text-info is-medium">
                        <i className="fas fa-chevron-right fa-lg" />
                      </span>
                    </div>
                  </div>
                )}

                {this.checkStep('4') ? (
                  <div className="step-block is-complete">
                    <div className="step-block__step">4.</div>
                    <div className="step-block__title">Hack a Network</div>
                    <div className="step-block__icon">
                      <span className="icon has-text-white is-medium">
                        <i className="fas fa-check fa-lg" />
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="step-block">
                    <div className="step-block__step">4.</div>
                    <div className="step-block__title">
                      {' '}
                      <a href={'/messenger/4'}>Hack a Network</a>
                    </div>
                    <div className="step-block__icon">
                      <span className="icon has-text-info is-medium">
                        <i className="fas fa-chevron-right fa-lg" />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              !!isLoading && <i className="fas fa-spinner fa-spin" />
            )}

            {/*
              Completed Button:
            */}
            {!isLoading && !error && steps.length >= 4 ? (
              <button className="button is-primary order-button is-rounded is-medium" onClick={this.tryOrder}>
                Order merchandise
              </button>
            ) : (
              ''
            )}

            {/*
              Incomplete Button:
            */}
            {!isLoading && !error && steps.length < 4 ? (
              <button
                className="button is-light order-button is-rounded is-medium incomplete"
                onClick={this.tryOrder}
              >
                Order merchandise
              </button>
            ) : (
              ''
            )}

            {/*
              Incomplete order message:
            */}
            {incompleteOrder ? (
              <div className="notification is-danger">
                {' '}
                You have to finish all steps before ordering. Otherwise you will easily get caught!
              </div>
            ) : (
              ''
            )}

            {/*
              API Error:
            */}
            {!isLoading && !!error ? (
              <h1>Something went wrong... ask the Risk Ident Booth for help :)</h1>
            ) : (
              ''
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default Dashboard;
