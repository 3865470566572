//@flow
import React, { Fragment } from 'react';
import { Redirect } from 'react-router';

import Auth from '../utils/Auth';
import { login } from '../service';
import logo from '../img/logo.png';
import spinner from '../img/spinner.png';

class Home extends React.Component {
  state = {
    isLoading: true,
    gameOver: false
  };

  registerUser = async () => {
    this.setState({ isLoading: true });
    try {
      const { data } = await login();
      Auth.authenticateUser(data.token);
      this.props.history.push('/messenger/intro');
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoading: !this.state.isLoading
      });
    }, 1000);
  }

  render() {
    if (Auth.isUserAuthenticated()) {
      return Auth.isIntroHidden() ? (
        <Redirect to="/dashboard" />
      ) : (
        <Redirect to="/messenger/intro" />
      );
    }

    const { isLoading, gameOver } = this.state;

    return (
      <section className="hero is-info is-fullheight">
        {gameOver ? (
          <React.Fragment>
            <div className="hero-body">
              <div className="container">
                <h1 className="title">The game is over!</h1>
                <h2 className="subtitle">Thank you for visiting Risk Ident's Fraud Game 2019.</h2>
              </div>
            </div>
            <div className="home-logo hero-foot">
              <img src={logo} alt="riskident logo" />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="hero-body">
              <div className="container">
                <h1 className="title">Welcome to the RISK IDENT fraud game!</h1>
                <h2 className="subtitle">
                  Collaborate with the Fraudster in the chat and try to "steal" a <strong>game-console</strong>. If
                  you are successful you will be eligible to take part in our raffle, and thus, have
                  a chance to win a <strong>Nintendo Switch</strong>.
                </h2>
                <div className="content">
                  <p>
                    <i>Game duration: 2min - 6min</i>
                  </p>
                  <p>
                    <small>The terms and conditions are available at the RISK IDENT booth.</small>
                  </p>
                  <p>
                    <strong>Have fun and Good luck!</strong>
                  </p>
                </div>
                {isLoading ? (
                  <Fragment>
                    <div className="loading-screen">
                      <img src={spinner} alt="loading" className="fa-spin" />
                    </div>
                  </Fragment>
                ) : (
                  <button
                    className={`button is-rounded is-white is-medium`}
                    onClick={this.registerUser}
                  >
                    Start the Game
                  </button>
                )}
              </div>
            </div>
            <div className="home-logo hero-foot">
              <img src={logo} alt="riskident logo" />
            </div>
          </React.Fragment>
        )}
      </section>
    );
  }
}

export default Home;
