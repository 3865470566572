//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { updateUser } from '../service';
class Register extends React.Component {
  state = {
    error: '',
    user: {
      email: '',
      name: '',
      company: '',
      optIn: false
    },
    isLoading: false
  };

  processForm = async () => {
    const { user } = this.state;
    if (!this.validateForm()) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      const { data } = await updateUser(user);
      this.props.history.push('/complete');
    } catch (error) {
      const msg =
        error && error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg
          : 'Internal server error';
      this.setState({
        error: msg,
        isLoading: false
      });
      setTimeout(() => this.setState({ errors: {} }), 3000);
      console.error(msg);
    }
  };

  /**
   * Change the user object.
   *
   * @param {object} event - the JavaScript event object
   */
  changeUser = event => {
    const field = event.target.name;
    if (field === 'optIn') {
      this.setState({
        user: {
          ...this.state.user,
          [field]: event.target.checked
        }
      });
    } else {
      this.setState({
        user: {
          ...this.state.user,
          [field]: event.target.value
        }
      });
    }
  };

  validateForm() {
    const { email, name } = this.state.user;
    let errorMsg = '';

    if (!email) {
      errorMsg += 'Email is required. ';
    }

    if (!name) {
      errorMsg += ' Name is required.';
    }
    this.setState({
      error: errorMsg
    });

    setTimeout(() => this.setState({ errors: {} }), 3000);

    if (errorMsg) {
      return false;
    }

    return true;
  }

  /**
   * Render the component.
   */
  render() {
    const { error } = this.state;

    return (
      <section className="hero is-fullheight is-info">
        <div className="hero-body">
          <div className="container">
            <h1 className="title is-4">
              Congratulations! You completed the RISK IDENT fraud game!
            </h1>
            <h2 className="subtitle is-6">
              You belong to the elite few that successfully completed the game! Good job!
            </h2>

            <div className="content">
              <p>
                Don't Stop now! To successfully join the raffle and be eligible to win a Nintendo
                Switch please sign up below:
              </p>
              {error && (
                <div className="columns">
                  <div className="column is-full">
                    <div className="notification is-danger">{error}</div>
                  </div>
                </div>
              )}
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Full Name (required)"
                    required
                    name="name"
                    value={this.state.user.name}
                    onChange={this.changeUser}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="email"
                    placeholder="Email (required)"
                    required
                    name="email"
                    value={this.state.user.email}
                    onChange={this.changeUser}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="company"
                    placeholder="Company  (optional)"
                    value={this.state.user.company}
                    onChange={this.changeUser}
                  />
                </div>
              </div>
              <label className="checkbox">
                <input type="checkbox" name="optIn" onChange={this.changeUser} /> I would like to be
                contacted and receive updates from RISK IDENT
              </label>
            </div>
          </div>
        </div>

        <div className="hero-foot">
          <button className="button is-info is-fullwidth is-large" onClick={this.processForm}>
            Go to next step
          </button>
        </div>
      </section>
    );
  }
}

export default withRouter(Register);
