import React from 'react';
import prize from '../img/nitendo.jpg';

const PhoneDescription = () => (
  <div className="card">
    <div className="card-image">
      <figure className="image is-fullwidth">
        <img src={prize} alt="Prize" />
      </figure>
    </div>
    <div className="card-content">
      <div className="media-content">
        <p className="title is-4">Nintendo Switch</p>
      </div>

      <div className="content">
        The Nitendo Switch is awesome. Everyone would love to have it. It's just a fingertip away from
        you! Order it now!
      </div>
    </div>
  </div>

);

export default PhoneDescription;
