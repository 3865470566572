import React from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

import './Messenger.scss';

import Chat from '../components/Chat';
import Messages from '../components/Messages';
import { getQuestion, answerQuestion, purchase } from '../service';
import Popup from '../components/Popup';
import Auth from '../utils/Auth';

const randomMessage = {
  intro: {
    messages: [
      `Psst, hey Buddy. You came to the right place if you want to try and steal the Nintendo Switch. It's easy. Simply complete the following tasks and you may have a chance to get it! All you need is:`,
      `1. A stolen identity. You don't want to use your own right?`,
      `2. A stolen credit card to "pay" for the merchendise.`,
      `3. A street address to pick up the merchandise at a safe location`,
      `4. A hacked proxy network to hide your true identity`
    ],
    stepType: 'intro'
  },
  '1': {
    message: 'Cool name...let me check it...',
    nextStep: '2'
  },
  '2': {
    message: 'Let me check...',
    nextStep: '3'
  },
  '3': {
    message: 'I will run a script to check if the address is secure....',
    nextStep: '4'
  },
  '4': {
    message: 'Checking....',
    stepType: 'wifi'
  },
  '5': {
    message: 'Checking....',
    stepType: 'retry'
  }
};

class Messenger extends React.Component {
  state = {
    chat: [],
    title: '',
    isLoading: false,
    numOfTry: 1,
    isCorrect: false,
    showWifiPopup: false
  };

  scrollToBottom = () => {
    if (document.getElementById('chatEnd')) {
      document.getElementById('chatEnd').scrollIntoView({ behavior: 'smooth' });
    }
  };

  fetchQuestion(step) {
    this.setState({ isLoading: true });
    getQuestion(step)
      .then(response => {
        this.setState({
          isLoading: false,
          chat: [
            ...this.state.chat,
            {
              userType: 'fraudster',
              message: (response.data || {}).question
            }
          ],
          title: (response.data || {}).title
        });
      })
      .catch(err => {
        console.error('Unable to fetch question', err);
        this.setState({ isLoading: false });
      });
  }

  writeIntro = messages => {
    if (!messages || messages.length === 0) {
      this.setState({
        isLoading: false
      });
      return;
    }
    setTimeout(() => {
      this.setState(
        {
          isLoading: true,
          chat: [
            ...this.state.chat,
            {
              userType: 'fraudster',
              message: messages[0]
            }
          ]
        },
        () => this.writeIntro(messages.slice(1))
      );
    }, 2000);
  };

  postAnswer = (answer, step) => {
    answerQuestion({
      step,
      numOfTry: this.state.numOfTry,
      answer: answer
    })
      .then(response => {
        const isCorrect = (response.data || {}).isCorrect;
        this.setState({
          isCorrect,
          numOfTry: isCorrect ? this.state.numOfTry : this.state.numOfTry + 1,
          isLoading: false,
          chat: [
            ...this.state.chat,
            {
              userType: 'fraudster',
              message: (response.data || {}).suggestion
            }
          ]
        });
      })
      .catch(err => {});
  };

  saveResponse = (msg, addUserResponse = true) => {
    const {
      match: { params }
    } = this.props;
    const stepType = randomMessage[params.step].stepType;
    let answer = msg;
    if (stepType === 'wifi') {
      this.setState({
        isLoading: true,
        showWifiPopup: false,
        chat: [
          ...this.state.chat,
          {
            userType: 'user',
            message: `<strong>Wifi Network:</strong> ${
              msg.network
            } <br /> <strong>Wifi password:</strong> ${msg.password}`
          }
        ]
      });
      answer = `${msg.network}:${msg.password}`;
    } else {
      this.setState({
        isLoading: true,
        chat: [
          ...this.state.chat,
          {
            userType: 'user',
            message: msg
          }
        ]
      });
    }

    setTimeout(() => {
      this.setState(
        {
          chat: [
            ...this.state.chat,
            {
              userType: 'fraudster',
              message: randomMessage[params.step].message
            }
          ]
        },
        () =>
          setTimeout(() => {
            this.postAnswer(answer, params.step);
          }, 2500)
      );
    }, 1000);
  };

  init(step) {
    if (!randomMessage[step]) {
      this.props.history.push('/dashboard');
      return;
    }
    if (step === 'intro') {
      Auth.isIntroHidden()
        ? this.props.history.push('/dashboard')
        : this.writeIntro(randomMessage[step].messages);
    } else if (Auth.isStepComplete(step)) {
      this.props.history.push('/dashboard');
    } else {
      this.fetchQuestion(step);
    }
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.init(params.step);
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.step !== this.props.match.params.step) {
      this.setState(
        {
          chat: [],
          title: '',
          isLoading: false,
          numOfTry: 1,
          isCorrect: false,
          showWifiPopup: false
        },
        () => {
          this.init(nextProps.match.params.step);
        }
      );
    }
  }

  toggleWifiPopup = () => {
    this.setState({
      showWifiPopup: !this.state.showWifiPopup
    });
  };

  footerTpl = step => {
    const { isCorrect } = this.state;
    const stepType = randomMessage[step].stepType;
    const nextStep = randomMessage[step].nextStep;
    if (isCorrect) {
      Auth.markStepAsComplete(step);
      return (
        <Link className="button is-success is-fullwidth is-large" to="/dashboard">
          Next
        </Link>
      );
    }
    switch (stepType) {
      case 'intro':
        return (
          <button
            className="button is-intro-button is-success is-fullwidth is-large"
            onClick={() => {
              Auth.hideIntro();
              this.props.history.push('/dashboard');
            }}
          >
            Next
          </button>
        );
      case 'wifi':
        return (
          <button
            className="button is-warning is-fullwidth is-large"
            onClick={this.toggleWifiPopup}
          >
            <span>Select Wifi Network </span>
            <span className="icon">
              <i className="fas fa-wifi" />
            </span>
          </button>
        );

      case 'retry':
        return (
          <button className="button is-info is-fullwidth is-large" onClick={this.retryPurchase}>
            Retry!
          </button>
        );
      default:
        return <Chat saveMsg={this.saveResponse} />;
    }
  };

  retryPurchase = async () => {
    try {
      const { data } = await purchase({});

      if (!data.success) {
        this.postAnswer('5', '5');
      } else {
        this.props.history.push('/register');
      }
    } catch (error) {
      this.postAnswer('5', '5');
    }
  };

  /**
   * Render the component.
   */
  render() {
    const { isLoading, showWifiPopup } = this.state;
    const {
      match: { params }
    } = this.props;

    if (!randomMessage[params.step]) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <section className="hero is-info is-fullheight">
        {showWifiPopup && (
          <Popup
            text="Join Network"
            closePopup={this.toggleWifiPopup}
            connect={networkInfo => this.saveResponse(networkInfo)}
          />
        )}

        {params.step === 'intro' ? (
          <div className="hero-head chat-header">
            <p className="title is-5">Intro</p>
            <p className="subtitle is-7">
              <strong>FraudChat</strong> | Online Fraud Forum 2019
            </p>
          </div>
        ) : (
          <div className="hero-head chat-header">
            {' '}
            <p className="title is-5">Todo: {this.state.title}</p>
            <p className="subtitle is-7">
              <strong>FraudChat</strong> | Online Fraud Forum 2019
            </p>
          </div>
        )}

        <div className="hero-body chat-body">
          <Messages chat={this.state.chat} />
          {isLoading && (
            <div className="loading">
              <strong>Fraudster</strong> is typing...
            </div>
          )}
        </div>

        <div className="hero-foot chat-footer">
          <footer>{this.footerTpl(params.step)}</footer>
        </div>
      </section>
    );
  }
}

export default Messenger;
