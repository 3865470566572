import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Messenger from './pages/Messenger';
import Checkout from './pages/Checkout';
import Auth from './utils/Auth';
import Complete from './pages/Complete';
import Register from './pages/Register';
import Confirmation from './pages/Confirmation';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Auth.isUserAuthenticated() ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />

    {/*Private Paths*/}
    <PrivateRoute exact path="/register" component={Register} />
    <PrivateRoute path="/dashboard" component={Dashboard} />
    <PrivateRoute path="/checkout" component={Checkout} />
    <PrivateRoute path="/confirmation" component={Confirmation} />
    <PrivateRoute exact path="/complete" component={Complete} />
    <PrivateRoute path="/messenger/:step" component={Messenger} />
    <Route component={Home} />
  </Switch>
);

export default Routes;
