import axios from 'axios';

// Default config options
const defaultOptions = {
  baseURL: process.env.REACT_APP_STAGE === 'dev' ? 'http://127.0.0.1:8080' : '/',
  headers: {
    'Content-Type': 'application/json'
  }
};

// Create instance
let HttpClient = axios.create(defaultOptions);

// Set the AUTH token for any request
HttpClient.interceptors.request.use(function(config) {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

HttpClient.interceptors.response.use(
  response => {
    // intercept the global error
    return response;
  },
  function(error) {
    if (error.response.status === 403) {
      localStorage.removeItem('token');
      return;
    }
    return Promise.reject(error);
  }
);

export default HttpClient;
