import React from 'react';
import './Popup.scss';

const networks = ['Risk White Web', 'Risk Dark Web', 'MRC London', 'fritzbox121', 'fritzbox123'];

class Popup extends React.Component {
  constructor() {
    super();
    this.state = {
      showInput: false,
      network: '',
      password: ''
    };
  }

  toggleInput = network => {
    this.setState({
      showInput: !this.state.showInput,
      network
    });
  };

  handlePasswordChange = event => {
    this.setState({
      password: event.target.value
    });
  };

  handleWifiConnect = () => {
    const { network, password } = this.state;
    console.log(network, password);
    this.props.connect({ network, password });
  };

  render() {
    return (
      <div className="popup animated">
        <div className="popup_inner">
          <h1 className="popup_heading">{this.props.text}</h1>
          <div className="networkList">
            {networks.map((network, i) => {
              return (
                <div
                  key={i}
                  className="networkList__item"
                  onClick={() => this.toggleInput(network)}
                >
                  <div className="networkList__name">{network}</div>
                  <span className="icon">
                    <i className="fas fa-wifi" />
                  </span>
                </div>
              );
            })}
          </div>
          <div className="networkList__close">
            <button className="button is-white" onClick={this.props.closePopup}>
              <span className="icon has-text-danger">
                <i className="fas fa-lg fa-times" />
              </span>
            </button>
          </div>

          {this.state.showInput ? (
            <div className="password-input">
              <div className="networkList__name">Connect to {this.state.network}</div>
              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    type="text"
                    placeholder="Enter network password"
                  />
                </div>
              </div>
              <div className="field">
                <button className="button is-info" onClick={this.handleWifiConnect}>
                  Connect
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Popup;
