import React from 'react';
import './Messages.scss';

const Messages = props => (
  <div className="chats">
    {props.chat.map((msg, i) => {
      return (
        <div
          key={i}
          className={`${msg.userType === 'fraudster' ? 'slideInLeft' : 'slideInRight'} animated`}
        >
          <p
            style={{
              padding: '.25em',
              textAlign: msg.userType === 'fraudster' ? 'left' : 'right',
              overflowWrap: 'normal'
            }}
          >
            <span
              key={i}
              className={`tag is-medium ${
                msg.userType === 'fraudster' ? 'chat-primary' : 'chat-secondary'
              }`}
              dangerouslySetInnerHTML={{ __html: msg.message }}
            />
          </p>
        </div>
      );
    })}
    <div id="chatEnd" />
  </div>
);
export default Messages;
